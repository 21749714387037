<template>
  <div class="px-1">
    <template
      v-if="
        (institution && !institution.kl_assessment) ||
        (user && user.groups.includes(7))
      "
    >
      <Page404></Page404>
    </template>
    <template v-else>
      <NewEvaluation
        :allows_crud="allows_crud"
        :evaluation_id="evaluation_id"
        :instrument_id="instrument_id"
      ></NewEvaluation>
      <NewDashboardCustomFab
        v-if="section"
        :section_id="section.id"
        :show_teacher_views="true"
      ></NewDashboardCustomFab>
    </template>
  </div>
</template>

<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";
export default {
  name: "NewEvaluationView",
  components: {
    NewEvaluation: () => import("./NewEvaluation"),
    NewDashboardCustomFab: () =>
      import("@/components/dashboard/NewDashboardCustomFab"),
    Page404: () => import("@/components/public/Page404"),
  },
  props: {},
  data() {
    return {
      evaluation_id: Number(this.$route.params.evaluation_id),
      instrument_id: Number(this.$route.params.instrument_id),
    };
  },
  computed: {
    ...mapGetters({
      user: "getUser",
      institution: "getInstitution",
      evaluations: names.NEW_EVALUATIONS,
      sections: names.SECTIONS,
    }),
    evaluation() {
      return this.evaluations.find((x) => x.id == this.evaluation_id);
    },
    section() {
      if (this.evaluation && this.evaluation.scope == 1)
        return this.sections.find((x) => x.id == this.evaluation.scope_id);
      else return null;
    },
    allows_crud() {
      if (this.user && this.section && !this.section.is_active) {
        const has_permission = this.$hasPermissions();
        if (has_permission) return has_permission;
        else return false;
      }
      const has_permission = this.$hasPermissions(
        "evaluations2.change_evaluation"
      );
      if (has_permission) return has_permission;
      else return false;
    },
  },
  methods: {},
  mounted() {},
  watch: {},
  created() {
    this.$store.dispatch(names.FETCH_NEW_TEST_TYPES);
    this.$store.dispatch(names.FETCH_REDACTION_QUESTION_LENGTH_ANSWERS);
    this.$store.dispatch(names.FETCH_MULTIPLE_QUESTION_SCORE_TYPES);
    this.$store.dispatch(names.FETCH_RUBRIC_PRESETS);
    this.$store.dispatch(names.FETCH_RUBRIC_PRESET_UNITS);
    this.$store.dispatch(names.FETCH_NEW_FILLING_QUESTION_SUB_TYPES);
    this.$store.dispatch(names.FETCH_TAXONOMIES);

    this.$store
      .dispatch(names.FETCH_NEW_EVALUATION, this.evaluation_id)
      .then((response) => {
        this.$store.commit(names.MUTATE_SHOW_INTELLIGENCE_SIDEBAR, true);
        this.$store.commit(names.MUTATE_RULES_FILTER_SELECTOR, {
          visible_intelligence_options: [7],
        });
        if (response.scope == 2 && response.scope_id != null) {
          this.$store
            .dispatch(names.FETCH_EGRESS_PROFILE_MATTER, response.scope_id)
            .then((egress_profile_matter) => {
              this.$store.commit(names.MUTATE_FILTER_INTELLIGENCE, {
                matter_id: egress_profile_matter.matter,
                show_message_evaluation_view: true,
                extra_context: "Para la Evaluación",
                intelligence_option: 7,
              });
            });
        }
        if (response.scope == 3 && response.scope_id != null) {
          this.$store.commit(names.MUTATE_FILTER_INTELLIGENCE, {
            matter_id: response.scope_id,
            show_message_evaluation_view: true,
            intelligence_option: 7,
          });
        }
      });
  },

  beforeDestroy() {
    this.$store.commit(names.MUTATE_SHOW_INTELLIGENCE_SIDEBAR, false);
    this.$store.commit(names.MUTATE_DELETE_FILTER_INTELLIGENCE);
    this.$store.commit(names.MUTATE_DELETE_RULES_FILTER_SELECTOR);
  },
};
</script>

<style scoped>
</style>
